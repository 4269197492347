html {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Verdana", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background: url("/covid.png") no-repeat bottom right;
  background-size: 20rem 20rem;
}

.main-container {
  margin-bottom: 20px;
}

.card h1 {
  margin-top: 1rem;
  font-size: 30px;
}

.page-header {
  margin-left: 15px;
  margin-top: 20px;
}

.btn-outline-info {
  background-color: #f8f9fa !important;
}

.btn-outline-info:hover {
  background-color: #17a2b8 !important;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
}

.title {
  color: grey;
  font-size: 18px;
}

.card a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.sidebar-container {
  min-width: 250px;
  max-width: 250px;
}

.sidebar-container button {
  margin-left: 18px;
}

ul.sidebar {
  margin: 0px;
  padding-left: 0px;
}

ul.sidebar li {
  margin-left: 10px;
  padding-right: 20px;
  list-style: none;
}

ul.sidebar.active {
  margin-left: -232px;
}

.welcome-container {
  width: 100%;
}

.sign-x-buttons {
  white-space: nowrap;
}

@media only screen and (max-width: 479px) {
  .covid-numbers {
    display: none !important;
  }

  .gisp-header span {
    display: none;
  }

  .gisp-header:after {
    content: "COVID-19 GISP";
  }
}

.page-header h3 {
  display: inline-block;
}

.covid-numbers {
  margin: 0px;
  display: inline-block;
}

.covid-numbers li {
  display: inline-block;
  list-style: none;
  margin-left: 10px;
  font-size: 0.9em;
  white-space: nowrap;
}
